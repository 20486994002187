<template>
    <div>
        <h1 class="pointer d-flex justify-center py-10" @click="$router.push('/')">ARMAWARFARE.RU</h1>
        <v-row class="mb-5">
            <v-col v-for="(col, idx) in icons" :key="idx" :cols="mobile ? 12 : 12 / icons.length"
                class="d-flex justify-center">
                <v-card class="pa-5" color="white" width="100" @click="$store.dispatch('opentab', col.link)">
                    <v-tooltip activator="parent" location="bottom">{{ col.tooltip }}</v-tooltip>
                    <v-img :src="col.icon"></v-img>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6" class="d-flex justify-end">
                <v-card max-width="500">
                    <v-card-title class="text-white" v-text="`RHSCUP SERVER`"></v-card-title>
                    <div class="mx-2 mx-sm-10">
                        <h4>armawarfare.ru:4302</h4>
                        <v-btn class="my-6 no-text-transform" min-width="100%" prepend-icon="mdi-link" color="blue-grey"
                            @click="$store.dispatch('opentab', '/rhscupfull.html')">
                            <span style="white-space: normal;">Полная коллекция модов MODS
                                FULL LIST</span></v-btn>
                        <v-btn class="no-text-transform" min-width="100%" prepend-icon="mdi-link" color="blue-grey"
                            @click="$store.dispatch('opentab', '/rhscupmini.html')"><span
                                style="white-space: normal;">Урезанная коллекция модов MODS
                                MINI LIST</span></v-btn>
                    </div>
                    <ServerCard class="ma-10" :rhs="true" :slides="7" />
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card max-width="500">
                    <v-card-title class="text-white" v-text="`VANILLA SERVER`"></v-card-title>
                    <div class="mx-2 mx-sm-10">
                        <h4>armawarfare.ru:2302</h4>
                        <v-btn prepend-icon="mdi-link" color="blue-grey" min-width="100%" class="mt-6"
                            @click="$router.push('/games')">VANILLA ТАБЛИЦА ИГР</v-btn>
                    </div>
                    <ServerCard class="ma-10" />
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'MainPage'
}
</script>

<script setup>
import { useDisplay } from "vuetify";

import ServerCard from "./servercard/ServerCard.vue";

//--Common constants--
const { mobile } = useDisplay();
const icons = [
    {
        icon: "/icons/discord.svg",
        link: "https://discord.gg/AESk4J4C4Y",
        tooltip: "Вступить в DISCORD",
    },
    {
        icon: "/icons/boosty.svg",
        link: "https://boosty.to/armawarfare.ru",
        tooltip: "BOOSTY",
    },
    {
        icon: "/icons/vk.svg",
        link: "https://vk.com/arma_warfare",
        tooltip: "Группа ВК",
    },
    {
        icon: "/icons/gametracker.png",
        link: "https://www.gametracker.com/search/arma3/?query=armawarfare.ru",
        tooltip: "GameTracker",
    },
    {
        icon: "/icons/youtube.svg",
        link: "https://www.youtube.com/@armawarfare",
        tooltip: "YouTube",
    },
];
</script>