<template>
    <v-navigation-drawer v-model="filterdrawer" temporary width="300">
        <v-form ref="form" class="ma-4" density="compact" :disabled="loading">
            <v-row no-gutters class="text-h5 ml-4 mb-4" align="center"
                >{{ $t('Filter', 2) }}<v-spacer></v-spacer>
                <v-btn icon size="small" flat @click="$emit('close')"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-row>
            <v-row no-gutters>
                <v-text-field
                    v-model="filter.MissionName"
                    :label="$t('MissionName')"
                    clearable
                    density="compact"
                >
                </v-text-field>
            </v-row>
            <v-row no-gutters>
                <Datepicker
                    v-model="filter.GameDates"
                    :format="formatDatePicker"
                    class="mt-2"
                    range
                    :locale="locale ? locale : 'en'"
                    :cancelText="$t('Cancel')"
                    :selectText="$t('Ok')"
                />
            </v-row>
            <v-row no-gutters>
                <v-autocomplete
                    v-model="filter.WinnerSide"
                    :items="storeDict('armasides')"
                    item-value="Code"
                    :item-title="(item) => `${item.Side} (${item.Text})`"
                    :label="$t('Side')"
                    clearable
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                        v-bind="props"
                        style="background-color: white;"
                            :title="item.raw.Side"
                            :subtitle="item.raw.Text"
                    ></v-list-item>
                    </template>
                </v-autocomplete>
            </v-row>            
            <v-row no-gutters>
                <v-btn
                    color="primary"
                    :loading="loading"
                    dark
                    block
                    class="mb-4"
                    @click="search"
                    >{{ $t("Apply") }}</v-btn
                >
            </v-row>
            <v-row no-gutters>
                <v-btn
                    class="mb-2"
                    block
                    :loading="loading"
                    @click="
                        () => {
                            filter = {};
                            $emit('search', {});
                        }
                    "
                    >{{ $t("ClearFilter") }}</v-btn
                >
            </v-row>
        </v-form>
    </v-navigation-drawer>
</template>

<script setup>
import {
    ref,
    reactive,
    defineProps,
    defineEmits,
    toRaw,
    inject,
    computed,
} from "vue";
import useDicts from "@/components/composables/dicts";
import rules from "@/components/rules";
import { formatDatePicker } from "@/components/utils"

const props = defineProps({
    loading: { type: Boolean, default: true },
});

const emit = defineEmits(["close", "search"]);
const filterdrawer = inject("filterdrawer");
const efwidth = ref(300);
const filter = reactive({});

const items = ref(["foo", "bar", "fizz", "buzz"]);
const values = ref(["foo", "bar"]);
const value = ref(null);

//--Reactive constants--
const form = ref(null);

//--Computed--
const locale = computed(() => localStorage.locale);

//--Methods--
const storeDict = useDicts();

async function search() {
    if ((await form.value.validate()).valid) {
        emit("search", { ...toRaw(filter),  });
    }
}
</script>