import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import validateRoute from "@/router/validator"
import i18n from '@/i18n'

import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

loadFonts()

//--Получить текущего пользователя до монтирования Vue приложения--
store.dispatch("initApplication", () => {
	router.beforeEach(to => {
		if (!validateRoute(to, store.getters.user))
			return '/';
	});
	store.commit("appLoaded");
});

const app = createApp(App)
	.use(router)
	.use(store)
	.use(vuetify)
	.use(i18n)
	.use(VCalendar, {});

export default app;

app.component('Datepicker', Datepicker);
app.mount('#app');