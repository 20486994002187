<template>
    <v-card style="overflow:auto">
        <table ref="chartstable" width="100%"></table>
    </v-card>
</template>

<script>
export default {
    name: "GameChart",
};    
</script>

<script setup>
import { ref, onMounted, defineProps, inject } from "vue";
import { getDictTextValue } from "@/components/utils";
import useDicts from "@/components/composables/dicts";

//--Props--
const props = defineProps({
    factions: Object,
    fackills: Object,
});

//--Pure constants--
const titleTextStyleTimes = {
    fontSize: 16,
    bold: true,
    italic: false,
};

const chartData = [
    "Игроки",
    "Убил игроков",
    "Уничтожил техники",
    "Смертей",
    "Очки",
    "Общее время",
    "Командовал",
];
const chartCommonData = [];
const windowInnerWidth = inject("windowInnerWidth");

//--Reactive constants--
//const chartdiv = ref();
const chartstable = ref();

//--Methods--
const storeDict = useDicts();
function drawChartPlayingTimes(titleTextStyle, faction, maxValue, element) {
    const data = new window.google.visualization.arrayToDataTable(faction.players);

    let annotations = [0];
    for (let i = 1; i < faction.players[0].length; i++) {
        annotations.push(i, {
            calc: "stringify",
            sourceColumn: i,
            type: "string",
            role: "annotation",
        });
    }

    let view = new window.google.visualization.DataView(data);
    view.setColumns(annotations);

    //--width - players count * 100 * 1.5 + 100 for left + 275 for right--
    //--height - max mins or score or inf kills * 1.5--
    let width = faction.players.length <= 6 && windowInnerWidth.value > 300 ? "100%" : 
        100 + 275 + (faction.players.length - 1) * 100 * 2.5;
    let height = maxValue * 2.5;

    let options = {
        chartArea: {
            left: 100,
            top: 35,
            bottom: 70,
            right: 275,
        },
        width,
        height,
        title: faction.title,
        titleTextStyle: { color: faction.color, ...titleTextStyle },
        legend: {
            textStyle: {
                fontSize: 12,
            },
        },
        vAxis: {
            title: "Время и очки",
            titleTextStyle: { fontSize: 12 },
            maxValue,
            textStyle: {
                fontSize: 12,
                color: faction.color,
                bold: true,
            },
        },
        hAxis: {
            title: "Игроки",
            titleTextStyle: { fontSize: 12 },
            textStyle: {
                fontSize: 12,
                color: faction.color,
                bold: true,
            },
        },
        seriesType: "bars",
        //series: {2: {type: 'area'}},
        //colors: ["#ffa500", "#ff8080", "#3366cc", "#109617"],
        bar: { groupWidth: "95%" },
    };

    let chart = new window.google.visualization.ComboChart(
        document.getElementById(element)
    );
    //chart.draw(data, options);
    chart.draw(view, options);
}

function drawChartFactories(titleTextStyle, faction, element) {
    // Create the data table.
    var data = new window.google.visualization.DataTable();
    data.addColumn('string', 'Topping');
    data.addColumn('number', 'Slices');
    data.addRows(faction.lostfactories);

    // Set chart options
    var options = {
        title: faction.title + ': потеряно заводов',
        titleTextStyle: { color: faction.color, ...titleTextStyle },
        width: windowInnerWidth.value,
        height: 500,
        pieSliceText: 'value-and-percentage',
        legend: {
            position: 'labeled',
            textStyle: {
                fontSize: 12
            }
        },
        is3D: true
    };

    //--Playing times--
    var chart = new window.google.visualization.PieChart(document.getElementById(element));
    chart.draw(data, options);
}

//--Hooks--
onMounted(() => {
    const allplayers = [];
    for (const [key, value] of Object.entries(props.factions)) {
        allplayers.push([...value]);
        chartCommonData.push({
            code: key,
            title: getDictTextValue(
                storeDict("armasides"),
                key,
                "Code",
                "Side"
            ),
            color: getDictTextValue(
                storeDict("armasides"),
                key,
                "Code",
                "Color"
            ),
            players: [[...chartData]].concat(value.map((x) => ([x.ArmaPlayerName.Name,
            x.PlayerKillsCount, (x.SoftVehKills + x.ArmorKills + x.AirKills), x.Deaths, x.TotalScore, Math.round(x.TotalTime / 60),
            Math.round(x.CommandTime / 60)]))),
            lostfactories: props.fackills.hasOwnProperty(key) ? 
                Object.entries(props.fackills[key]).map(x => [x[0] + ": " + x[1].map(y => " " + y.player + " " + Math.round(y.time/60) + " мин"), x[1].length]) : 
                null
        });
    }

    for (const [key] of Object.entries(props.fackills)) {
        if(chartCommonData.findIndex(x => x.code == key) == -1)
            chartCommonData.push({
                lostfactories: Object.entries(props.fackills[key]).map(x => [x[0] + ": " + x[1].map(y => " " + y.player + " " + Math.round(y.time/60) + " мин"), x[1].length])
            });
    }

    let maxValue = Math.max.apply(
        this,
        allplayers
            .reduce((accumulator, currentValue) =>
                accumulator.concat(currentValue)
            )
            .filter((el) => typeof el == "number")
    );

    if (maxValue < 150) maxValue = 150;
    const table = chartstable.value;
    let rowHead = table.insertRow();

    chartCommonData.forEach((faction, index) => {
        if(Array.isArray(faction.players) && faction.players.length > 0) {
            let row = table.insertRow();
            let cell = row.insertCell();
            let div = document.createElement("div");
            div.id = "cell" + index;
            div.style.maxWidth = `${windowInnerWidth.value - 2}px`;
            div.style.overflowX = "auto";
            div.style.whiteSpace = "nowrap";
            cell.appendChild(div);
        }
        if(Array.isArray(faction.lostfactories) && 
            faction.lostfactories.length > 0) {
            const facsrow = table.insertRow();
            let faccell = facsrow.insertCell();
            faccell.id = "faccell" + index;
        }
    });

    const headCell = rowHead.insertCell();
    const buttomCell = table.insertRow().insertCell();
    //headCell.innerHTML = head;
    headCell.style.textAlign = "center";
    //buttomCell.innerHTML = buttom;
    buttomCell.style.textAlign = "center";

    window.google.charts.load("current", { packages: ["corechart"] });
    for (let i = 0; i < chartCommonData.length; i++)
        window.google.charts.setOnLoadCallback(() => {
            if(Array.isArray(chartCommonData[i].players) && 
                chartCommonData[i].players.length > 0)
                drawChartPlayingTimes(
                    titleTextStyleTimes,
                    chartCommonData[i],
                    maxValue,
                    "cell" + i
                );
            if(Array.isArray(chartCommonData[i].lostfactories) && 
                chartCommonData[i].lostfactories.length > 0)
                window.google.charts.setOnLoadCallback(function() { drawChartFactories(
                    titleTextStyleTimes, chartCommonData[i], 'faccell' + i); });
        });
});
</script>