// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify, VDataTable } from 'vuetify'

export default createVuetify({
    components: {
        VDataTable,
    },
	theme: {
		defaultTheme: 'dark'
	}
})
