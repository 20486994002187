const rules = {
    required: value => {
        if (value !== 0)
            return Array.isArray(value) && value.length > 0 || (!Array.isArray(value) && !!value) || "Требуется значение";
        else
            return true;
    },
    counter: value => value.length <= 20 || "Максимум 20 символов",
    email: value => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        .test(value) || "Неверный e-mail",
    // eslint-disable-next-line
    url: value => value == null || value === "" || /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(value) || "Требуется указать URL",
    mobile: value => value == null || /^(\+\d{1,3}[- ]?)?\d{10}$/.test(value) || /^\+?\d\(\d{3}\)\d{3}-?\d{2}-?\d{2}$/.test(value) || "Неверный номер телефона",
    ip: value => {
        const pattern = /\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b$/
        return pattern.test(value) || "Неверный ip"
    },
    datatypeInteger: value => Number.isInteger(Number(value)) || "Тебуется целое число",
    datatypeIntegerGZ: value => (Number.isInteger(Number(value)) && value > 0) || "Тебуется целое число больше 0",
    datatypeNumber: value => !isNaN(Number(value)) || "Тебуется число",
    datatypeNumberGZ: value => { 
        value = parseFloat(String(value).replace(/\s/g, ''));
        return (!isNaN(value) && value > 0) || "Тебуется число больше 0" 
    },
    arraylength: value => Array.isArray(value) && value.length > 0 || "Требуется выбрать хотя бы одно значение",
    nullOrInteger: value => {
        var result = true;

        if (value != null && value !== "" && !Number.isInteger(Number(value)))
            result = "Требуется целое число";

        return result;
    },
    nullOrIntegerGZ: value => {
        var result = true;

        if (value != null && value !== "" && (!Number.isInteger(Number(value)) ||  value <= 0))
            result = "Требуется целое число больше 0";

        return result;
    },
    nullOrNumber: value => {
        var result = true;

        if (value != null && value !== "" && isNaN(Number(value)))
            result = "Требуется число";

        return result;
    },
    nullOrNumberGZ: value => {
        var result = true;

        if (value != null && value !== "" && (isNaN(Number(value)) || value <= 0))
            result = "Требуется число больше 0";

        return result;
    },
    filepicture: value => !Array.isArray(value) || value.length == 0 || ["image/png", "image/jpeg"].includes(value[0].type) || 'Недопустимый тип файла',
    fileicon: value => !Array.isArray(value) || value.length == 0 || ["image/png", "image/svg+xml"].includes(value[0].type) || 'Недопустимый тип файла',
    filesize: value => !Array.isArray(value) || value.length == 0 || value[0].size < 5242880 || 'Размер файла не должен превышать 5МБ',
    filesize1mb: value => !Array.isArray(value) || value.length == 0 || value[0].size < 1048576 || 'Размер файла не должен превышать 1МБ',
    filessize: value => {
        if(!Array.isArray(value))
            return true;
            
        var valid = true;
        var limit = 10485760;
        var counter = 0;

        value.forEach(val => {
            counter += val.size;
        });

        if (counter > limit)
            valid = 'Общий размер не должен превышать 10МБ';

        return valid;
    },
    futureDate: value => {
        const date = new Date();
        date.setHours(0,0,0,0);
        value = new Date(value);
        value.setHours(0,0,0,0);
        return value > date || 'Требуется будущая дата';
    },
    currentOrFutureDate: value => {
        const date = new Date();
        date.setHours(0,0,0,0);
        value = new Date(value);
        value.setHours(0,0,0,0);
        return value >= date || 'Требуется текущая или будущая дата';
    },
    isRegExp: value => {
        let result = true;
        try {
            new RegExp(value);
        } catch (err) {
            result = false;
        }
        return result || "Требуется валидное регулярное выражение";
    },
};

export default rules;