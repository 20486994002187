<template>
    <v-card elevation="24" :max-width="$isMobile ? '90%' : 444" class="mx-auto">
        <v-carousel cycle :show-arrows="false" hide-delimiters height="240">
            <v-carousel-item v-for="(slide, i) in [...Array(slides).keys()].filter(x => x > 0)" :key="i">
                <v-sheet height="100%" tile>
                    <div class="d-flex fill-height justify-center align-center">
                        <v-img
                            :src="`/pictures/${props.rhs ? 'RHS' : 'VANILLA' }${slide}.jpg`"
                        ></v-img>
                    </div>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
    </v-card>
</template>

<script setup>
import { defineProps } from "vue";

//--Props--
const props = defineProps({
    rhs: {
        type: Boolean,
        default: false
    },
    slides: {
        type: Number,
        default: 6
    },
});
</script>