import router from '@/router/'

//--Справочники--
const dicts = {
    armasides: {
        path: "armasides",
        parse: false,
        data: [],
    },
    armawarfarestructure: {
        path: "armawarfarestructure",
        parse: false,
        data: [],
    },
    specialroles: {
        path: "roles/specials",
        parse: false,
        data: [],
        resolveCallback: dict => {
            const route = router.getRoutes().find(x => new RegExp(/^\/gamesevents\/?$/i).test(x.path));
            if(route && route.meta && Array.isArray(route.meta.roles)) {                
                const news = dict.data.find(x => x.type == "news")
                if(news && Array.isArray(news.values))
                    news.values.forEach(x => !route.meta.roles.includes(x) ? route.meta.roles.push(x) : null);
            }
        }
    },
    discordguildroles: {
        path: "roles/discordguildroles",
        loading: true,
        parse: false,
        data: [],
    },
}

export { dicts };