<template>
    <div class="text-center">
        <span class="font-weight-medium span">404</span>
        <h3 class="mb-12">Этой страницы не существует</h3>
        <v-btn
            depressed
            color="primary"
            @click="
                () => {
                    $router.push('/');
                }
            "
            >ВЕРНУТЬСЯ НА ГЛАВНУЮ</v-btn
        >
    </div>
</template>

<style scoped>
.span {
    font-size: 350px;
}
</style>