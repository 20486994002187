import { createRouter, createWebHistory } from 'vue-router';

//--Components--BEGIN--------------------------------------------------------------------------------------------//
import NotFound from '@/components/pages/NotFound.vue'
import MainPage from '@/components/pages/MainPage.vue'

import ReplaysPage from '@/components/pages/replays/ReplaysPage.vue'
import GamesPage from '@/components/pages/games/GamesPage.vue'
//--Components--END----------------------------------------------------------------------------------------------//

const routes = [
    { path: '/', component: MainPage },
    { path: "/:catchAll(.*)", component: NotFound },

    { name: 'replays', path: '/replays', component: ReplaysPage, props: { url: `http://armawarfare.ru:5050/` } },
    { name: 'replaysrhs', path: '/replaysrhs', component: ReplaysPage, props: { url: `http://armawarfare.ru:5150/` } },
    { path: '/games', component: GamesPage },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        return {
            el: to.hash,
            top: 70
        };
    },
});

export default router;