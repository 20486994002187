<template>
    <v-table>
        <thead>
            <tr>
                <th class="text-left">№</th>
                <th class="text-left">Никнейм</th>
                <th class="text-left">Сторона</th>
                <th class="text-left">Общее время</th>
                <th class="text-left">Командовал раз</th>
                <th class="text-left">Убил игроков</th>
                <th class="text-left">Убил всего пехоты</th>
                <th class="text-left">Уничтожил легкой техники</th>
                <th class="text-left">Уничтожил тяжелой техники</th>
                <th class="text-left">Уничтожил воздушной техники</th>
                <th class="text-left">Смертей</th>
                <th class="text-left">Игровые очки</th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="loading">
                <td colspan="9">
                    <v-progress-linear indeterminate></v-progress-linear>
                </td>
            </tr>
            <template
                v-for="(item, idx) in search != null && search.length > 0
                    ? items.filter(
                          (x) =>
                              x.Id == search ||
                              x.ArmaPlayerName.Name.includes(search)
                      )
                    : items"
                :key="item.Id"
            >
                <tr
                    @click="rowclick(item, idx)"
                    :bgcolor="idx === selected ? colors.grey.lighten1 : ''"
                    :class="idx === selected ? 'selected' : ''"
                >
                    <td>{{ idx + 1 }}</td>
                    <td>{{ item.ArmaPlayerName.Name }}</td>
                    <td>
                        {{
                            getDictTextValue(
                                storeDict("armasides"),
                                item.Side,
                                "Code",
                                "Side"
                            )
                        }}
                    </td>
                    <td>{{ toTimeStamp(item.TotalTime) }}</td>
                    <td>{{ item.WasCommanderTimes }}</td>                    
                    <td>
                        <v-menu transition="scale-transition" :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-btn flat variant="outlined" v-bind="props"
                                    :loading="item.loading"
                                    @click.stop="() => { if(item.PlayerKillsCount > 0)getpk(idx, item.ArmaPlayerName.Id) }">
                                    {{ item.PlayerKillsCount }}
                                </v-btn>
                            </template>

                            <PlayerKills v-if="item.hasOwnProperty('loading') && item.loading == false" 
                                :items="pkitems" 
                                :OCAPFile="item.ArmaGame.OCAPFile" />
                        </v-menu>
                    </td>
                    <td>{{ item.InfKills }}</td>
                    <td>{{ item.SoftVehKills }}</td>
                    <td>{{ item.ArmorKills }}</td>
                    <td>{{ item.AirKills }}</td>
                    <td>{{ item.Deaths }}</td>
                    <td>{{ item.TotalScore }}</td>
                </tr>
            </template>
        </tbody>
    </v-table>
</template>

<script setup>
import { ref, onMounted, defineProps } from "vue";
import colors from "vuetify/lib/util/colors";
import { ajaxGet } from "@/ajax";
import useDicts from "@/components/composables/dicts";
import { getDictTextValue, toTimeStamp } from "@/components/utils";

//--Components--
import PlayerKills from "./extra/PlayerKills.vue";

//--Pure constatns--
const storeDict = useDicts();

//--Reactive constatns--
const loading = ref(true);
const items = ref([]);
const pkitems = ref([]);
const selected = ref(null);
const search = ref(null);

//--Props--
const props = defineProps({
    gameId: Number,
});

//--Methods--
function rowclick(item, idx) {
    selected.value = idx;
}

function getpk(idx, playerNameId) {
    items.value[idx].loading = true;
    ajaxGet(
        "/armaplayerkills",
        { filter: JSON.stringify({ where: { ArmaGameId: props.gameId, KillerNameId: playerNameId } }) },
        (response) => {
            if ([200, 201].includes(response.status))
                pkitems.value = response.data;

            items.value[idx].loading = false;
        }
    );
}

//--Hooks--
onMounted(() => {
    ajaxGet(
        "/armagamearmaplayernames",
        { filter: JSON.stringify({ where: { ArmaGameId: props.gameId }, order: ["Side"] }) },
        (response) => {
            if ([200, 201].includes(response.status))
                items.value = response.data;

            loading.value = false;
        }
    );
});
</script>