<template>
    <v-app>
        <v-app>
            <v-snackbar v-model="showsnackbar" :timeout="snackbar.duration" :color="snackbar.type" multi-line>
                <div v-if="snackbar.title" class="text-h6" style="color: white">{{ snackbar.title }}</div>
                <span style="color: white">{{ snackbar.message }}</span>
                <template v-slot:actions>
                    <v-btn color="white" icon @click="showsnackbar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
            <router-view v-if="!appLoading"></router-view>
            <v-row v-else>
                <v-col cols="12" class="d-flex justify-center align-center">
                    <v-progress-circular :size="70" indeterminate></v-progress-circular>
                </v-col>
            </v-row>
        </v-app>
    </v-app>
</template>

<script setup>
import app from "@/main";
import { ref, computed, watch, onMounted, provide } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useDisplay } from "vuetify";
import validateRoute from "@/router/validator"

//--Components--

//--Common constants--
const { mobile } = useDisplay();
app.config.globalProperties.$isMobile = mobile.value;

const store = useStore();
const router = useRouter();
const route = useRoute();

//--Reactive constants--
const showsnackbar = ref(false);
const category = ref("pages");
provide("category", category);

//--Computed--
const appLoading = computed(() => store.state.common.appLoading);
const snackbar = computed(() => store.getters.snackbar);
const user = computed(() => store.getters.user);
provide("user", user);
const windowInnerWidth = computed(() => window.innerWidth);
provide("windowInnerWidth", windowInnerWidth);
const windowInnerHeight = computed(() => window.innerHeight);
provide("windowInnerHeight", windowInnerHeight);

//--Watchers--
// eslint-disable-next-line
watch(snackbar, (newvalue) => {
    showsnackbar.value = true;
});

watch(appLoading, (newvalue) => {
    let path = window.location.pathname;
    if (path.slice(-1) === "/")
        path = path.slice(0, -1);
    const route = router.options.routes.find(x => x.path == path);

    if (!newvalue && route)
        if (!validateRoute(route, store.getters.user))
            //--Проверить права на маршрут после загрузки страницы (необходима в случаях ручного ввода маршурта через адресную строку)--
            //--Так как при первой загрузке страницы навигационный хук router-а еще не был задан--
            router.push("/");
});

//--Methods--
const storageListener = () => {
    if (localStorage.shared === "true" && localStorage.sharedData) {
        const sharedData = JSON.parse(localStorage.sharedData);
        if (sharedData.user) store.commit("setuser", sharedData.user);

        if (sharedData.snackbar)
            store.dispatch("snackbar", {
                ...sharedData.snackbar,
                message: 
                    // sharedData.snackbar.t
                    // ? t(sharedData.snackbar.t)
                    // : 
                    sharedData.snackbar.message,
            });

        if (sharedData.steamdata)
            store.commit("setuser", {
                ...store.getters.user,
                nickname: sharedData.steamdata.newnickname ? sharedData.steamdata.newnickname : store.getters.user.nickname,
                steamdata: sharedData.steamdata
            });

        localStorage.shared = false;
        localStorage.removeItem("sharedData");
    }
};

//--Hooks--
onMounted(() => {
    // let apiYandexMapsScript = document.createElement("script");
    // apiYandexMapsScript.setAttribute(
    //     "src",
    //     `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${process.env.VUE_APP_YANDEX_MAPS_KEY}`
    // );
    // document.head.appendChild(apiYandexMapsScript);
    let googlechartsScript = document.createElement("script");
    googlechartsScript.setAttribute(
        "src",
        "https://www.gstatic.com/charts/loader.js"
    );
    document.head.appendChild(googlechartsScript);
    window.addEventListener("storage", storageListener);
});
</script>

<style>
.pointer {
    cursor: pointer;
}
</style>