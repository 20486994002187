<template>
    <div>
        <v-dialog v-model="chartdialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
            <v-card>
                <div
                    :style="{ backgroundColor: $vuetify.theme.current.colors.primary, color: 'white', fontSize: '24px' }">
                    <v-btn icon flat color="rgba(255,255,255,0)" @click="chartdialog = false" class="ma-3">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <strong>Карта: </strong>{{ game.TerrainName }} /
                    <strong>Миссия: </strong>{{ game.MissionName }} /
                    <strong>Победитель: </strong><span :style="{ color: getDictTextValue(
                        storeDict('armasides'),
                        game.WinnerSide,
                        'Code',
                        'Color'
                    ) }">{{ getDictTextValue(
                    storeDict("armasides"),
                    game.WinnerSide,
                    "Code",
                    "Side"
                    ) }}
                    </span> /
                    <strong>Время: </strong>{{ toTimeStamp(Math.round(game.MissionTime)) }}
                </div>
                <GameChart :factions="gcitems" :fackills="gcfackills" />
            </v-card>
        </v-dialog>

        <GamesFilter :loading="loading" :value="filterdrawer" @search="getgames" @close="filterdrawer = false" />

        <v-row no-gutters>
            <v-col cols="3">{{ $t("Game", 2) }} ({{ gamescount }})</v-col>
            <v-col cols="4"></v-col>
            <v-col cols="3">
                <v-text-field v-if="!loading && games.length > 0" v-model="search" append-icon="mdi-magnify" single-line
                    hide-details density="compact"></v-text-field>
            </v-col>
            <v-col></v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="10">
                <v-btn icon flat size="small" @click="filterdrawer = !filterdrawer">
                    <v-tooltip activator="parent" location="bottom">{{
                    $t("Filter", 2)
                    }}</v-tooltip>
                    <v-icon>mdi-filter-variant</v-icon>
                </v-btn>
                <v-chip v-for="(tag, idx) in chips" :key="idx" class="mx-2" :disablied="loading">
                    <strong>{{ tag[2] }}:&nbsp;</strong>
                    {{ tag.length > 3 ? tag[3] : tag[1] }}
                    &nbsp;
                    <v-btn size="3" flat icon color="primary" @click="chipclose(tag[0])">
                        <v-icon size="small">mdi-close</v-icon>
                    </v-btn>
                </v-chip>
            </v-col>
        </v-row>

        <v-row no-gutters="">
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <v-divider></v-divider>
            </v-col>
        </v-row>

        <v-table>
            <thead>
                <tr>
                    <th class="text-left">№</th>
                    <th class="text-left">{{ $t("Mission") }}</th>
                    <th class="text-left">{{ $t("Terrain") }}</th>
                    <th class="text-left">{{ $t("Winner") }}</th>
                    <th class="text-left">{{ $t("Duration") }}</th>
                    <th class="text-left">{{ $t("PlayersCount") }}</th>
                    <th class="text-left">{{ $t("Start") }}</th>
                    <th class="text-left">{{ $t("End") }}</th>
                    <th v-if="moderator">
                        <v-icon>mdi-flash</v-icon>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="loading">
                    <td colspan="10">
                        <v-progress-linear indeterminate></v-progress-linear>
                    </td>
                </tr>
                <template v-for="(item, idx) in search != null && search.length > 0
                ? games.filter(
                      (x) =>
                          x.Id == search ||
                          x.MissionName.includes(search) ||
                          x.TerrainName.includes(search)
                  )
                : games" :key="item.Id">
                    <tr @click="rowclick(item, idx)" :bgcolor="idx === selected ? colors.grey.lighten1 : ''"
                        :class="idx === selected ? 'selected' : ''">
                        <td>
                            <strong>{{ item.Id }}</strong>
                            <v-btn class="ml-6" flat icon size="x-small" :loading="item.loading"
                                @click.stop="openchart(idx, item)">
                                <v-tooltip activator="parent" location="bottom">
                                    Открыть диаграмму </v-tooltip>
                                <v-icon>mdi-chart-bar</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <span class="
                                    font-weight-medium
                                    text-decoration-underline
                                " style="cursor: pointer" @click.stop="
                                    $router.push(
                                        `/replays?file=${item.OCAPFile.replace(/\.gz$/, '')}&frame=0`
                                    )
                                ">
                                <v-tooltip activator="parent" location="bottom">
                                    Открыть реплей
                                </v-tooltip>
                                {{ item.MissionName }}
                            </span>
                        </td>
                        <td>{{ item.TerrainName }}</td>
                        <td>
                            {{
                            getDictTextValue(
                            storeDict("armasides"),
                            item.WinnerSide,
                            "Code",
                            "Side"
                            )
                            }}
                        </td>
                        <td>{{ toTimeStamp(item.MissionTime) }}</td>
                        <td>
                            <v-btn :append-icon="
                                playersExpanded.includes(idx)
                                    ? 'mdi-chevron-up'
                                    : 'mdi-chevron-down'
                            " size="small" flat @click.stop="
                                playersExpanded.includes(idx)
                                    ? playersExpanded.splice(
                                          playersExpanded.findIndex(
                                              (x) => x == idx
                                          ),
                                          1
                                      )
                                    : playersExpanded.push(idx)
                            ">
                                {{ item.PlayersCount }}
                            </v-btn>
                        </td>
                        <td>{{ toLocaleString(item.StartedAt) }}</td>
                        <td>{{ toLocaleString(item.EndedAt) }}</td>
                        <td v-if="moderator">
                            <v-btn flat size="x-small" icon :loading="item.loading" @click="deletegame(idx, item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    <tr v-if="playersExpanded.includes(idx)">
                        <td colspan="10">
                            <GamePlayers :gameId="item.Id" />
                        </td>
                    </tr>
                </template>
            </tbody>
        </v-table>
        <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="1">
                <v-select v-model="rowsperpage" :label="$t('Show')" density="compact" :items="[
                    5,
                    10,
                    15,
                    20,
                    25,
                    { value: -1, title: $t('All') },
                ]"></v-select>
            </v-col>
            <v-col cols="3">
                <v-pagination v-model="page" size="small" :length="
                    rowsperpage > 0
                        ? Math.ceil(gamescount / rowsperpage)
                        : 1
                "></v-pagination>
            </v-col>
            <v-col cols="1"></v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "GamesPage",
};    
</script>

<script setup>
import { ref, reactive, toRaw, onMounted, provide, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ajaxGet, ajaxDel } from "@/ajax";
import { removeNils } from "@/ajax/utils";
import {
    getDictTextValue,
    dateToString,
    toLocaleString,
    toTimeStamp,
} from "@/components/utils";
import { useI18n } from "vue-i18n";
import colors from "vuetify/lib/util/colors";
import useDicts from "@/components/composables/dicts";

//--Components--
import GamesFilter from "./GamesFilter.vue";
import GamePlayers from "./GamePlayers.vue";
import GameChart from "@/components/charts/GameChart.vue";

//--Pure constants--
const store = useStore();
const route = useRoute();
const { t } = useI18n();
const storeDict = useDicts();

//--Reactive constatns--
const loading = ref(true);
const search = ref(null);
const selected = ref(null);
const game = ref(null);
const filterdrawer = ref(false);
const chartdialog = ref(false);
provide("filterdrawer", filterdrawer);
const filter = reactive({});
const expandfirst = ref(false);

const games = ref([]);
const chips = ref([]);
const gamescount = ref(0);
const page = ref(1);
const rowsperpage = ref(10);
const playersExpanded = ref([]);
const gcitems = ref([]);
const gcfackills = ref({});

//--Computed--
const offset = computed(() =>
    page.value > 1 ? rowsperpage.value * (page.value - 1) : 0
);
const moderator = computed(() => {
    let result = false;
    if (Array.isArray(store.getters.user.roles) && store.getters.dicts.specialroles)
        for (let i = 0; i < store.getters.user.roles.length; ++i)
            if (store.getters.dicts.specialroles.data.findIndex(x => x.type == "news"
                && x.values.includes(store.getters.user.roles[i])) > -1) {
                result = true;
                break;
            }
    return result;
});

//--Watchers--
watch(rowsperpage, () => {
    page.value = 1;
    getgames();
});

watch(page, () => {
    getgames();
});

//--Methods--
function computeFilterField(data) {
    const result = [...data];
    switch (data[0]) {
        case "MissionName":
            result.push(t("MissionName"));
            break;
        case "GameDates":
            result.push(`${t("Start")} ${t("End")}`);
            result.push(
                result[1]
                    .filter((x) => x != null)
                    .map(dateToString)
                    .join(", ")
            );
            break;
        case "WinnerSide":
            result.push(t("Side"));
            result.push(
                getDictTextValue(
                    storeDict("armasides"),
                    result[1],
                    "Code",
                    "Side"
                )
            );
            break;
        default:
            result.push(data[0]);
    }

    return result;
}

function rowclick(item, idx) {
    selected.value = idx;
}

function chipclose(field) {
    delete filter[field];
    getgames();
}

function getgames(filterdata = null, raw = false) {    
    const filterbuff = raw ? filterdata.where : filterdata;
    if (filterdata) {
        if (Object.keys(filterbuff).length == 0)
            Object.keys(filter).forEach((key) => {
                delete filter[key];
            });
        else Object.assign(filter, JSON.parse(JSON.stringify(filterbuff)));
    }

    loading.value = true;
    chips.value = [];
    chips.value = Object.entries(removeNils(filter))
        .filter((x) => x[1] != null)
        .map(computeFilterField);

    const query = {
        where: JSON.parse(JSON.stringify(filter)),
        limit: raw && filterdata.limit ? filterdata.limit : (rowsperpage.value > 0 ? rowsperpage.value : null),
        offset: raw && filterdata.offset ? filterdata.offset : offset.value,
        order: raw && filterdata.order ? filterdata.order : [["Id", "DESC"]]
    };

    ajaxGet("/armagames", { filter: JSON.stringify(removeNils(query)) }, (response) => {
        if ([200, 201].includes(response.status)) {
            gamescount.value = response.data.count;
            games.value = response.data.rows;
            if(expandfirst.value)
                playersExpanded.value.push(0);
        }

        loading.value = false;
    });
}

function openchart(idx, ArmaGame) {
    game.value = ArmaGame;
    games.value[idx].loading = true;
    ajaxGet(
        "/armagamearmaplayernames",
        { filter: JSON.stringify({ where: { ArmaGameId: ArmaGame.Id }, order: ["Side"] }) },
        (response) => {
            if ([200, 201].includes(response.status)) {                
                gcitems.value = response.data.reduce((group, item) => {
                    const { Side } = item;
                    group[Side] = group[Side] ?? [];
                    group[Side].push(item);
                    return group;
                }, {});
                ajaxGet("/armawarfarestructurekill",
                { filter: JSON.stringify({ where: { ArmaGameId: ArmaGame.Id }, order: ["Side"] }) },
                (response) => {
                    games.value[idx].loading = false;
                    gcfackills.value = {};
                    if ([200, 201].includes(response.status)) {
                        if(Array.isArray(response.data) && response.data.length > 0) {                            
                            response.data.forEach(value => {
                                const structureCode = getDictTextValue(storeDict("armawarfarestructure"), 
                                        value.ArmaWarfareStructureId, "Id", "Code");
                                if(!gcfackills.value.hasOwnProperty(value.Side))
                                    gcfackills.value[value.Side] = {};
                                if(!gcfackills.value[value.Side].hasOwnProperty(structureCode))
                                    gcfackills.value[value.Side][structureCode] = [];
                                gcfackills.value[value.Side][structureCode].push({
                                    player: value.ArmaPlayerName.Name,
                                    time: value.MissionTime,
                                })
                            });
                        }
                        chartdialog.value = true;
                    } else
                        store.dispatch("snackbar", {
                            message: response.message,
                            type: "error",
                        });
                });   
            } else {
                store.dispatch("snackbar", {
                    message: response.message,
                    type: "error",
                });
                games.value[idx].loading = false;
            }
        }
    );
}

function deletegame(idx, item) {
    if (window.confirm("Удалить запись?")) {
        games.value[idx].loading = true;
        ajaxDel("/armagames/" + item.Id, null,
            (response) => {
                if (response.data == 1)
                    games.value.splice(idx, 1);
                else
                    store.dispatch("snackbar", {
                        message: response.message,
                        type: "error",
                    });

                games.value[idx].loading = false;
            }
        );
    }
}

//--Hooks--
onMounted(() => {
    if(route.query.expandfirst && route.query.expandfirst !== "false")
        expandfirst.value = true;

    let filter = null;
    if(route.query.filter)
        try {
            filter = JSON.parse(route.query.filter)
        } catch(ex) {null}
    getgames(filter, filter ? true : false);
});
</script>

<style>
.selected:hover {
    background: #bdbdbd !important;
}
</style>