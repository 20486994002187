<template>
    <div :style="{ width: '100%', height: '100%' }">
        <div v-if="loading" class="d-flex h-100 justify-center align-center">        
            <v-progress-circular
                :size="70"
                indeterminate
            ></v-progress-circular>
        </div>
        <iframe
            ref="replays"
            :style="{ display: loading ? 'none' : 'block' }"
            :src="`${props.url}${query}`" 
            frameborder="0"
            scrolling="no"
            width="100%"
            height="100%"
        ></iframe>
    </div>
</template>

<script setup>
import { ref, defineProps, onMounted, computed } from "vue";
import { useRouter } from "vue-router";

//--Props--
const props = defineProps({
    url: String,
});

const router = useRouter();
const replays = ref();
const loading = ref(true);

const query = computed(() => {
    let qr = router.currentRoute.value.query;
    if(Object.keys(qr).length > 0)
        qr = "?" + Object.keys(router.currentRoute.value.query).map(key => `${key}=${router.currentRoute.value.query[key]}`).join('&');
    else
        qr = "";
    return qr;
});

onMounted(() => {
    replays.value.onload = () => {
        loading.value = false;
    };
});
</script>