export default {
    en: {
        Errors: {
            Main: 'Server connection error. Reload the page later or contact the administrator',
            Error: 'Error'
        },
        Warnings: {
            'WindowPopup': "Popup windows doesn't allowed! Please turn on in settings of your browser",
            'AuthorizationRequired': "You should to authorized before"
        },
        Ok: 'Ok',
        Cancel: 'Cancel',
        Close: 'Close',
        All: 'All',
        Game: 'Game | Games',
        Side: 'Side | Sides',
        Terrain: 'Terrain | Terrains',
        Mission: 'Mission | Missions',
        MissionName: 'Mission name',
        Winner: 'Winner',
        Duration: 'Duration',
        PlayersCount: 'Players Count',
        Start: 'Start',
        End: 'End',
        SessionExpired: 'Session Expired',

        SignIn: 'Sign in',
        SignOut: 'Sign out',
        Registration: 'Registration',
        Apply: 'Apply',

        Filter: 'Filter | Filters',
        ClearFilter: 'Clear',
        Show: 'Show',

        Discord: {
            ShouldJoin: "You should join to our Discord Server"
        },

        Steam: {
            Linked: "Steam account linked to the user!"
        },
    },

    ru: {
        Errors: {
            Main: 'Ошибка соединения с сервером. Перезагрузите страницу позже или свяжитесь с администратором',
            Error: 'Ошибка'
        },
        Warnings: {
            'WindowPopup': "Пожалуйста, разрешите всплывающие окна в настройках вашего браузера",
            'AuthorizationRequired': "Вам необходимо осуществить вход на сайт под своей учетной записью"
        },
        Ok: 'Ок',
        Cancel: 'Отмена',
        Close: 'Закрыть',
        All: 'Все',
        Game: 'Игра | Игры',
        Side: 'Сторона | Стороны',
        Terrain: 'Карта | Карты',
        Mission: 'Миссия | Миссии',
        MissionName: 'Название миссии',
        Winner: 'Победитель',
        Duration: 'Продолжительность',
        PlayersCount: 'Количество Игроков',
        Start: 'Начало',
        End: 'Конец',
        SessionExpired: 'Время сессии истекло, необходимо авторизоваться',

        SignIn: 'Войти',
        SignOut: 'Выйти',
        Registration: 'Зарегистрироваться',
        Apply: 'Применить',

        Filter: 'Фильтр | Фильтры',
        ClearFilter: 'Сбросить',
        Show: 'Отображать',

        Discord: {
            ShouldJoin: "Вы должны присоединиться к нашему серверу Discord"
        },

        Steam: {
            Linked: "Steam присоединён к учетной записи!"
        },
    }
}